//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-412:_5044,_3176,_3716,_8708,_3264,_5904,_8948,_7796;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-412')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-412', "_5044,_3176,_3716,_8708,_3264,_5904,_8948,_7796");
        }
      }catch (ex) {
        console.error(ex);
      }