//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-412:_5140,_5704,_5116,_1484,_7948,_468,_4440,_5068;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-412')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-412', "_5140,_5704,_5116,_1484,_7948,_468,_4440,_5068");
        }
      }catch (ex) {
        console.error(ex);
      }